import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileService from '../../../services/FileService';
import { FileInfo } from './FileInfo';
import UploadArea from './UploadArea';
import { ModalContext } from '../../../contexts/ModalContext';
import StandardModal from '../modal/variants/StandardModal';
import CropImageModal from './CropImageModal';

type ImageUploadModalProps = {
  open: boolean;
  title: string;
  description?: string;
  file?: FileInfo | null;
  onCancel: () => void;
  onImageChanged: (imageId: string) => void;
};

const ImageUploadModal: FC<ImageUploadModalProps> = (props) => {
  const { t } = useTranslation('common');
  const { title, description, open, file, onCancel, onImageChanged } = props;
  const [internalFile, setInternalFile] = useState<FileInfo | null>(null);
  const [progress, setProgress] = useState<number | undefined>();
  const [error, setError] = useState<string | undefined>();

  const [cropModalOpen, setCropModalOpen] = useState(false);

  const uploadFile = (file: File) => {
    setProgress(0);
    FileService.uploadFile(file, (perc) => {
      setProgress(perc);
    })
      .then((res) => {
        setInternalFile((file) => file && { ...file, id: res.data[0].id, mimeType: res.data[0].contentType });
        setProgress(undefined);
        onImageChanged && onImageChanged(res.data[0].id);
      })
      .catch((err) => {
        setError(err.meta.message);
      });
  };

  useEffect(() => {
    setInternalFile(file || null);
  }, [file, open]);

  const onUpload = (files: FileInfo[]): void => {
    const file = files[0];
    setInternalFile(file);
    setError(undefined);

    if (!file) {
      return;
    }

    if (file.size >= FileService.MAX_SIZE) {
      setError(t('file-upload.errors.file-to-large'));
      return;
    }

    setCropModalOpen(true);
  };

  const onCancelClick = () => {
    setInternalFile(null);
    setError(undefined);
    setCropModalOpen(false);
    onCancel && onCancel();
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [open]);

  if (!open) {
    return <></>;
  }

  return (
    <ModalContext.Provider value={{ open: open, onClose: onCancel, modalWidth: 'w-2/5' }}>
      <StandardModal title={title} subTitle={description} onCancelClick={onCancelClick} confirmDisabled={!!error || progress !== undefined}>
        <div className="pb-7">
          {!internalFile && <UploadArea onUpload={onUpload} acceptMimes="image/jpg,image/png,image/gif" />}
          {!!internalFile && (
            <CropImageModal
              open={cropModalOpen}
              onClose={onCancelClick}
              file={internalFile.file}
              onCropped={uploadFile}
              confirmButtonTitle={t('profile.image-upload.button')}
              maxDimension={150}
            />
          )}
        </div>
      </StandardModal>
    </ModalContext.Provider>
  );
};

export default ImageUploadModal;
